<template>
  <div style="width: 50%;">
    <h1>Empty project setting</h1>
    <div style="display:flex; flex-wrap:wrap; ">
      <table>
        <tr>
          <th>Fields</th>
          <td>
            <input v-model="sCount[0]" style="width: 50px;" type="number" min="1" max="32"> x
            <input v-model="sCount[1]" style="width: 50px;" type="number" min="1" max="32">
          </td>
        </tr>
        <tr>
        <tr>
          <th>Background color</th>
        <td>
          <div class="c-background-item" style="height: 20px;width: 20px;">
            <VuePickr
              v-model="setup.background"
              @clear="bgClear"
            />
          </div>
        </td>
        </tr>
        <tr><tr>
          <th>Background spaces</th>
          <td>
            <input v-model="sSpaces" style="width: 50px;" type="number" min="0" max="96">
          </td>
        </tr>
        <tr>
          <th>Grid</th>
          <td>
            <input v-model="setup.grid" style="width: 50px;" type="number" min="-96" max="96">
          </td>
        </tr>
        <tr>
          <th>Settings</th>
          <td>
            <button :class="setup.rotate?'bg_2':''" @click.prevent="setup.rotate=!setup.rotate" class="aggregated_field_add_button " style=" font-size: 20px">Rotation</button>
            <button :class="setup.fullRandom?'bg_2':''"      @click.prevent="setup.fullRandom=!setup.fullRandom" class="aggregated_field_add_button" style=" font-size: 20px">Mix</button>
            <button :class="setup.hotGenerating?'bg_2':''"     @click.prevent="setup.hotGenerating=!setup.hotGenerating" class="aggregated_field_add_button " style=" font-size: 20px">Live</button>
          </td>
        </tr>
        <tr>
          <th>Modules collection</th>
          <td>
            <select style="display: block" v-model="sCollection">
              <option
                v-for="(item,key) in selCollections"
                :key ="'collection' + key"
              >{{item.text}}</option>
            </select>
          </td>
        </tr>
        <tr>
          <th>Palettes</th>
          <td>
            <select style="display: block" v-model="setup.palettes">
              <option
                v-for="(item,key) in palettesOption"
                :key ="'palettes' + key"
              >{{item}}</option>
            </select>
          </td>
        </tr>
      </table>
    </div>
    <div style="width: 100%;margin-top: 30px; flex-wrap: wrap; display:flex;justify-content:space-between;">
      <button @click="saveChanges" class="aggregated_field_add_button bg_2" style=" font-size: 20px">Save & exit</button>
      <button @click="refresh" class="aggregated_field_add_button bg_5" style=" font-size: 20px">Reset</button>
    </div>

  </div>
</template>

<script>
import VuePickr from '../components/VuePickr.vue'

export default {
  components: {
    VuePickr
  },
  data: () => ({
    setup: {
      count: '[6,4]',
      grid: 10,
      background: null,
      rotate: true,
      fullRandom: false,
      hotGenerating: false,
      collection: 'None',
      palettes: 'Favorite',
      spaces: '{"left":0,"top":0,"right":0,"bottom":0}'
    },
    sCount: [6, 4],
    sSpaces: 0,
    sCollection: 'None',
    dbCollections: [{}],
    palettesOption: ['None', 'Favorite', 'All'],
    selCollections: [{ value: '', text: 'None' }]
  }),
  watch: {
    sCollection (value) {
      this.setup.collection = this.selCollections.filter(c => c.text === value)[0].value
    },
    sCount (value) {
      this.setup.count = '[' + value.join(',') + ']'
    },
    sSpaces (value) {
      this.setup.spaces = `{"left":${value},"top":${value},"right":${value},"bottom":${value}}`
    }
  },
  methods: {
    refresh () {
      this.$router.go()
    },
    saveChanges () {
      this.$store.dispatch('setNewDataToTable', { type: 'preset', data: JSON.stringify(this.setup) })
      this.$router.push('/')
    },
    bgClear () {
      this.setup.background = null
    }

  },
  async mounted () {
    await this.$store.dispatch('getAllCollectionData')
    this.dbCollections = this.$store.getters.data
    this.dbCollections.filter(c => c.private === false).forEach(sel => {
      this.selCollections.push({ value: sel.key, text: sel.name })
    })
    await this.$store.dispatch('getDirectValue', 'preset')
    this.setup = JSON.parse(this.$store.getters.data)
    this.sCount = JSON.parse(this.setup.count)
    this.sCollection = this.selCollections.filter(c => c.value === this.setup.collection)[0].text
    this.sSpaces = JSON.parse(this.setup.spaces).left
  }
}
</script>
